/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function MarketingFooter(props) {
  const { pageFooter, overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="1440px"
      height="380.59px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="40px 60px 40px 60px"
      backgroundColor="rgba(51,98,67,1)"
      {...rest}
      {...getOverrideProps(overrides, "MarketingFooter")}
    >
      <Flex
        gap="300px"
        width="1294px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="20px 20px 20px 20px"
        {...getOverrideProps(overrides, "Frame 420")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="137.47px"
          shrink="0"
          height="224px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 415")}
        >
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32534052869")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052870")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052871")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Secure Badge Access Entry"
              {...getOverrideProps(overrides, "Lorem Ipsum34052872")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32634052873")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052874")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052875")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="En Suite Private Bathroom"
              {...getOverrideProps(overrides, "Lorem Ipsum34052876")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32734052877")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052878")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052879")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Kitchenette"
              {...getOverrideProps(overrides, "Lorem Ipsum34052880")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32834052881")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052882")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052883")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="CAT6 Pre-wired"
              {...getOverrideProps(overrides, "Lorem Ipsum34052884")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32934052885")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052886")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052887")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="High Ceilings"
              {...getOverrideProps(overrides, "Lorem Ipsum34052888")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 33034052889")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052890")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052891")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Security System Available"
              {...getOverrideProps(overrides, "Lorem Ipsum34052892")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="137.47px"
          shrink="0"
          height="224px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 414")}
        >
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32534052844")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052845")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052846")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Modern Workplace in Excellent Condition"
              {...getOverrideProps(overrides, "Lorem Ipsum34052847")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32634052848")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052849")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052850")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Central Air and Heating"
              {...getOverrideProps(overrides, "Lorem Ipsum34052851")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32734052852")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052853")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052854")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Tile/Lamenate Flooring"
              {...getOverrideProps(overrides, "Lorem Ipsum34052855")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32834052856")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052857")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052858")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Video Ready Conference Room"
              {...getOverrideProps(overrides, "Lorem Ipsum34052859")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32934052860")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052861")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052862")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Storage Room"
              {...getOverrideProps(overrides, "Lorem Ipsum34052863")}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 33034052864")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon34052865")}
            >
              <Icon
                width="20px"
                height="17px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 17 }}
                paths={[
                  {
                    d: "M10 2.69L15 7.19L15 15L13 15L13 9L7 9L7 15L5 15L5 7.19L10 2.69L10 2.69ZM10 0L0 9L3 9L3 17L9 17L9 11L11 11L11 17L17 17L17 9L20 9L10 0Z",
                    fill: "rgba(255,255,255,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="12.5%"
                bottom="16.67%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector34052866")}
              ></Icon>
            </View>
            <Text
              fontFamily="Segoe UI"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Natural Light"
              {...getOverrideProps(overrides, "Lorem Ipsum34052867")}
            ></Text>
          </Flex>
        </Flex>
        <View
          width="262px"
          height="274.59px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 421")}
        >
          <Image
            width="170.18px"
            height="163px"
            position="absolute"
            bottom="-13.74px"
            right="78.62px"
            transformOrigin="top left"
            transform="rotate(-45deg)"
            borderRadius="30px"
            padding="0px 0px 0px 0px"
            src="https://fgnamp-ou-website-storage-images03629-staging.s3.amazonaws.com/protected/FGNLogo_Only.gif"
            {...getOverrideProps(overrides, "Rectangle 1166")}
          ></Image>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="right"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.01px"
            position="absolute"
            bottom="5px"
            right="5px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="© Copyright FGN Holding2022"
            {...getOverrideProps(overrides, "Lorem ipsum dolor sit amet 2021")}
          ></Text>
        </View>
      </Flex>
    </Flex>
  );
}
