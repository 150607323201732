/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function NavBar(props) {
  const { navHeader, overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      height="305px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="38px 50px 38px 50px"
      backgroundColor="rgba(165,210,183,1)"
      {...rest}
      {...getOverrideProps(overrides, "NavBar")}
    >
      <Flex
        gap="65px"
        width="1340px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="40px 23px 40px 23px"
        {...getOverrideProps(overrides, "Frame 418")}
      >
        <Flex
          gap="32px"
          width="388px"
          height="147px"
          justifyContent="flex-end"
          alignItems="center"
          grow="1"
          basis="388px"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(165,210,183,1)"
          {...getOverrideProps(overrides, "Frame 32129767081")}
        >
          <Image
            width="388px"
            grow="1"
            basis="388px"
            alignSelf="stretch"
            position="relative"
            borderRadius="20px"
            padding="0px 0px 0px 0px"
            src="https://fgnamp-ou-website-storage-images03629-staging.s3.amazonaws.com/protected/Exterior.JPG"
            {...getOverrideProps(overrides, "Rectangle 1165")}
          ></Image>
        </Flex>
        <Flex
          gap="2px"
          width="388px"
          height="147px"
          justifyContent="center"
          alignItems="center"
          grow="1"
          basis="388px"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Logo")}
        >
          <Image
            width="388px"
            height="131px"
            grow="1"
            basis="388px"
            position="relative"
            borderRadius="20px"
            padding="0px 0px 0px 0px"
            src="https://fgnamp-ou-website-storage-images03629-staging.s3.amazonaws.com/protected/FGNLogo_Artwork.png"
            {...getOverrideProps(overrides, "Rectangle 1164")}
          ></Image>
        </Flex>
        <Flex
          gap="9px"
          direction="column"
          width="388px"
          height="147px"
          grow="1"
          basis="388px"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 32129767076")}
        >
          <Text
            fontFamily="Segoe UI"
            fontSize="24px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="30px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="center"
            letterSpacing="0.09px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="P.O. Box 515"
            {...getOverrideProps(overrides, "Address1")}
          ></Text>
          <Text
            fontFamily="Segoe UI"
            fontSize="24px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="30px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="center"
            letterSpacing="0.09px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="White Marsh, MD 21162"
            {...getOverrideProps(overrides, "Address2")}
          ></Text>
          <Text
            fontFamily="Segoe UI"
            fontSize="24px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="30px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="center"
            letterSpacing="0.09px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="410.497.7608"
            {...getOverrideProps(overrides, "Phone")}
          ></Text>
          <Text
            fontFamily="Segoe UI"
            fontSize="24px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="30px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="center"
            letterSpacing="0.09px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="sales@fgnholding.com"
            {...getOverrideProps(overrides, "Email")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
