/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function Features2x1(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      height="316px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 138px 0px 138px"
      backgroundColor="rgba(239,240,240,1)"
      {...rest}
      {...getOverrideProps(overrides, "Features2x1")}
    >
      <Flex
        gap="10px"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Frame 419")}
      >
        <Text
          fontFamily="Segoe UI"
          fontSize="32px"
          fontWeight="600"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Property Overview"
          {...getOverrideProps(overrides, "Feature A")}
        ></Text>
      </Flex>
      <Flex
        gap="109px"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 136")}
      >
        <View
          width="516px"
          height="229px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(239,240,240,1)"
          {...getOverrideProps(overrides, "Frame 374")}
        >
          <Text
            fontFamily="Segoe UI"
            fontSize="20px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.05px"
            width="448px"
            height="182px"
            position="absolute"
            top="23px"
            left="40px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Exquisite and modern office condo in the heart of White Marsh. The condo project was constructed in 2008 and offers plenty of covered parking and a large shared boardroom for tenant use. The site is located only minutes to I95, Rt 40, Franklin Square Hospital & all of the retail amenities within the White Marsh region."
            {...getOverrideProps(
              overrides,
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.29766596"
            )}
          ></Text>
        </View>
        <View
          width="526px"
          height="227px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(239,240,240,1)"
          {...getOverrideProps(overrides, "Frame 373")}
        >
          <Text
            fontFamily="Segoe UI"
            fontSize="20px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.05px"
            width="436px"
            height="190px"
            position="absolute"
            top="23px"
            left="40px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="The suite available is a unit offering an expansive window line with plenty of natural light throughout. Included within the suite is a secure vesitbule entrance, three private rooms, ensuite private bathroom, kitchenette, cipy area & storage room. Perfect for a growing company!"
            {...getOverrideProps(
              overrides,
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.29766599"
            )}
          ></Text>
        </View>
      </Flex>
    </Flex>
  );
}
