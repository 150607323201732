/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function HeroLayout1(props) {
  const { bodyHero1, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "LOREM IPSUM": {},
        "Building Space": {},
        Address1: {},
        Address2: {},
        "City, State Zip": {},
        "Office Space": {},
        Message: {},
        Button: {},
        HeroMessage: {},
        Left: {},
        image: {},
        Right: {},
        HeroLayout1: {},
      },
      variantValues: { mode: "Light" },
    },
    {
      overrides: {
        "LOREM IPSUM": {},
        "Building Space": {},
        Address1: {},
        Address2: {},
        "City, State Zip": {},
        "Office Space": {},
        Message: {},
        Button: {},
        HeroMessage: {},
        Left: {
          padding: "120px 120px 120px 120px",
          backgroundColor: "rgba(165,210,183,1)",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        },
        image: { alignSelf: "stretch", objectFit: "cover" },
        Right: {},
        HeroLayout1: {},
      },
      variantValues: { mode: "Dark" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const buttonOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://outlook.office365.com/owa/calendar/FGNPropertyViewing@fgnholding.com/bookings/",
  });
  return (
    <Flex
      gap="0"
      width="fit-content"
      height="500px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "HeroLayout1")}
    >
      <Flex
        gap="10px"
        direction="column"
        width="720px"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="83px 120px 83px 120px"
        backgroundColor="rgba(204,231,214,0.71)"
        {...getOverrideProps(overrides, "Left")}
      >
        <Flex
          gap="10px"
          direction="column"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="16px 0px 16px 0px"
          {...getOverrideProps(overrides, "HeroMessage")}
        >
          <Text
            fontFamily="Segoe UI"
            fontSize="16px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="24px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="COMMERCIAL SUITE FOR SALE OR LEASE"
            {...getOverrideProps(overrides, "LOREM IPSUM")}
          ></Text>
          <Text
            fontFamily="Segoe UI"
            fontSize="24px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="30px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="White Marsh Professional Center"
            {...getOverrideProps(overrides, "Building Space")}
          ></Text>
          <Flex
            gap="4px"
            direction="column"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="18px 0px 18px 0px"
            {...getOverrideProps(overrides, "Message")}
          >
            <Text
              fontFamily="Segoe UI"
              fontSize="20px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.05px"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="5430 Campbell Blvd."
              {...getOverrideProps(overrides, "Address1")}
            ></Text>
            <Text
              fontFamily="Segoe UI"
              fontSize="20px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.05px"
              height="27px"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Suite 101"
              {...getOverrideProps(overrides, "Address2")}
            ></Text>
            <Text
              fontFamily="Segoe UI"
              fontSize="20px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.05px"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="White Marsh, MD 21162"
              {...getOverrideProps(overrides, "City, State Zip")}
            ></Text>
            <Text
              fontFamily="Segoe UI"
              fontSize="20px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.05px"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="950 - 1100 SF Office Space"
              {...getOverrideProps(overrides, "Office Space")}
            ></Text>
          </Flex>
          <Button
            display="flex"
            gap="0"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Schedule Viewing"
            onClick={() => {
              buttonOnClick();
            }}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="720px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Right")}
      >
        <Image
          width="720px"
          height="500px"
          grow="1"
          basis="500px"
          position="relative"
          padding="0px 0px 0px 0px"
          src="https://fgnamp-ou-website-storage-images03629-staging.s3.amazonaws.com/protected/FGN_Concept_Landscape.PNG"
          {...getOverrideProps(overrides, "image")}
        ></Image>
      </Flex>
    </Flex>
  );
}
