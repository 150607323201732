// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { BodyHero1, NavHeader, PageFooter, OfficeView } = initSchema(schema);

export {
  BodyHero1,
  NavHeader,
  PageFooter,
  OfficeView
};