import './App.css';
import { NavBar, HeroLayout1, Features2x1, OfficeViewCollection, MarketingFooter } from "./ui-components";

function App() {
  return (
    <div className="App">
      <NavBar width={"100vw"} />
      <HeroLayout1 width={"100vw"} />
      <Features2x1 width={"100vw"} />
      <OfficeViewCollection />
      <MarketingFooter width={"100vw"} />
    </div>
  );
}

export default App;
